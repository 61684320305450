.container-main {
  footer {
    bottom: 0;
    margin-top: 20px;
    .container {
      padding-top: 10px;
    }
    background-color: $light;
    border-top: 1px solid #e5e5e5;
    ul {
      padding: 0;
      margin: 0;
    }
    ul, ol {
      list-style: none;
    }
    .social-widget {
      ul.social-icons {
        text-align: right;
        li {
          display: inline-block;
          margin-right: 4px;
          margin-bottom: 4px;
          a {
            text-decoration: none;
            color: $disabled;
            i {
              font-size: 30px;
              padding-right: 15px;
              padding-top: 5px;
            }
          }
          a:hover, a:active {
            color: $primary;
          }
        }
      }
    }

    .footer-widget {
      img {
        font-weight: 700;
        position: relative;
      }
    }

    .copyright {
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1px;
      p {
        padding-top: 10px;
      }
    }

    @media (max-width: $screen-sm-max) {
      .copyright {
        text-align: center;
      }
      .social-widget {
        ul.social-icons {
          text-align: center;
          li {
            a {
              i {
                margin-bottom: 20px;
                font-size: 20px;
                padding-right: 15px;
                padding-top: 5px;
              }
            }
            a:hover, a:active {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
