.container-main {
  .carousel-caption {
    display: none;
  }
  .carousel-control {
    width: 10%;
    opacity: 0.2;
    i {
      color: #fff;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      z-index: 5;
      display: inline-block;
      opacity: 0.7;
    }
  }
  .carousel-indicators {
    li {
      border: 2px solid $primary;
    }
    .active {
      border: 1px solid $light;
      background-color: $primary;
    }
  }
  section.presenter {
    margin-bottom: 10px;
    img {
      max-height: 600px;
      width: 100%;
    }

  }
}
