.container-main {
  .anchor {
    content: "";
    display: block;
    height: 50px;
    margin: -50px 0 0;
  }
  header {
    padding: 0;
    nav.navbar-default {
      .container {
        padding-left: 25px;
      }
      .dropdown-menu {
        background-color: $light;
      }

      border-radius: 0;
      margin: 0 0 5px 0;
      padding: 4px 0 0 0;
      background-color: $light;
      @media (min-width: $screen-md-min) {
        .container {
          padding-left: 40px;
        }
        .navbar-right > li.active > a, .navbar-right > li:hover > a {
            border-bottom: 2px solid $primary;
            background-color: transparent;;

        }

        .navbar-right > li > a {
              border-bottom: 2px solid transparent;
        }
      }
      .dropdown-menu>li>a {
        padding: 10px;
      }
      .dropdown-menu>li>a:hover , .dropdown-menu>li>a:active {
        background-color: $background;
      }
      .header-icons {
        border-bottom: none;
        margin-left: 10px;
        margin-top: 10px;
        a {
          margin-left: 8px;
          text-decoration: none;
          color: $disabled;
          i {
            font-size: 22px;
          }
        }
        a:hover, a:active {
          color: $primary;
        }
      }
      .navbar-brand {
        padding: 0;
        img {
          max-height: 90%;
        }
      }
    }
  }
}
