@import "./variables";
@import "./../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";


// Core Bootstrap
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/buttons";

//Utils
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/carousel";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";

// NavigationBar
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/navbar";



//News
// @import "./../../node_modules//bootstrap-sass/assets/stylesheets/bootstrap/media";



body {
  margin: 0;
  padding: 0;
  background-color: $background;
  font-family: 'Open Sans', sans-serif;
  height: 100%;

  .container-main {
    a {
      text-decoration: none;
    }
    width:100%;
    position:absolute;
    min-height:100% !important; /* browser fill */
    height:auto;
    .content-body {
      margin-top: 60px;
      padding-top: 10px;
      img {
        max-width: 100%;
      }
      p {
        text-align: justify;
      }
      i.large {
        padding-top: 10px;
        font-size: 40px;
      }
    }
    .text-center {
      text-align: center;
    }
    .container {
      @media (max-width: $screen-md-min) {
        width: 100%;
      }
    }
    h1 {
      font-size: 3rem;
      padding-bottom: 15px;
    }
    h2 {
      font-size: 2.2rem;
      padding-bottom: 4px;
      width: 100%;
      border-bottom: solid 2px $light;
    }
    @media (max-width: $screen-sm-max) {
      h1 {
        font-size: 2rem;
      }
      h2 {
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
  }
  .breadcrumb {
    padding: 10px 2px;
  }
}

// Partials
@import "./partials/header";
@import "./partials/presenter";
@import "./partials/services";
@import "./partials/works";
//@import "./partials/news";
@import "./partials/clients";

@import "./partials/footer";

// Pages
@import "./pages/about";
@import "./pages/contact";
@import "./pages/services";
@import "./pages/works-finances";
@import "./pages/works-pegasus";
