section.about {
  .about-detatil {
    img {
      width: 100%;
      padding-bottom: 15px;
      max-width: 400px;
    }
  }
  p {
    text-align: justify;
  }
}
