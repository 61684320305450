section.contact {
  .container {
    .contact-datail {
      font-size: 16px;
      padding-bottom: 15px;
    }

    .successForm, .errorForm {
      display:none
    }
    .successForm div, .errorForm div {
      display:block;
      position:absolute;
      margin-top: -350px;
      width: 99%;
      font-size: 20px;
    }
    .successForm div p, .errorForm div p {
      margin-top: 150px;
      margin-right: 20px;
      padding: 20px 10px;
      background-color: #ffffff;
    }
    .successForm div p {
      color:#9bd32d;
      border: 1px solid #9bd32d;
    }
    .errorForm div p {
      color:#c0392b;
      border: 1px solid #c0392b;
    }
  }
}
