.container-main {
  .img-box {
    text-align: center;
    padding: 15px 0;
  }
  section.services {
    .list-services {
      margin: 0;
    }
    p {
      margin-bottom: 5px;
    }
    .box {
      margin: 5px;
      text-align: center;

      padding: 5px;
      border-radius: 10px;
      color: $disabled;
      .title {
        font-weight: bold;
        text-align: center;
      }
      i {
        font-size: 50px;
        padding-bottom: 10px;
      }
      i.small {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 35px;
      }
      .description {
        min-height: 76px;
        text-align: center;
      }
    }
    .box:active, .box:hover {
      color: $primary;
      background-color: $light;
    }
  }
}
