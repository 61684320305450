.container-main {
  section.clients {
    .container {
      padding-top: 20px;
      text-align: center;
      .clients-header{
        background-color: $light;
        padding: 5px;
        h2 {
          font-size: 26px;
          line-height: 32px;
          font-weight: 300;
          // margin-bottom: 0;
          margin: 0;
        }
        h3 {
          margin: 4px 0 0 0;
          font-size: 16px;
          line-height: 22px;
          color: $disabled;
        }
      }

      .clients-content {
        margin-top: 10px;
      }

      .logo-clients {
        img {
          padding: 10px;
          width: 100%;
          max-width: 200px;
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter ….3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
          filter: grayscale(100%);
          transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
        }
        img:hover, img:active  {
          background-color: $light;
          border-radius: 10px;
          -webkit-filter: none;
          -moz-filter: none;
          -o-filter: none;
          filter: none;
          transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
        }
      }
    }
  }
}
