.container-main {
  section.works {
    .container {
      margin-top: 10px;
      .works-header {
        padding: 5px;
        text-align: center;
        background-color: $light;
        h2 {
          font-size: 26px;
          font-weight: 300;
          margin: 0;
        }
        h3 {
          margin: 4px 0 0 0;
          font-size: 16px;
          line-height: 22px;
          color: $disabled;
        }
      }
      .works-content {
        margin-top: 10px;
        text-align: center;
        a {
          text-decoration: none;
          img {
            padding: 5px;
            max-width: 100%;
            border: 1px solid $light;
            border-radius: 15px;
          }
          .work-description {
            text-align: center;
            @media (max-width: $screen-xs-max) {
              padding-bottom: 15px;
            }
          }
        }
        a:hover, a:active {
          img{
            background-color: $light;
          }
        }
      }
    }
  }
  section.pegasus {
    .inovations {
      padding: 15px;
    }
  }
}
